import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss' // global css
import router from './router'
import store from './store'
import './icons' // 自动渲染svg
import VeLine from 'v-charts/lib/line'
import { request } from '@/network/network'
import axios from 'axios'
import NProgress from 'nprogress'
import moment from 'moment'
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
import VueAMap from 'vue-amap';




VueAMap.initAMapApiLoader({
  key: '674b7926677e1a9827678cd7a26428b1',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
Vue.use(VueAMap)



//cors

Vue.use(ElementUI)
Vue.prototype.$http = request;

Vue.component(VeLine.name, VeLine)

Vue.config.productionTip = false
Vue.filter('dateFormat', function (dateStr,pattern = "YYYY-MM-DD HH:mm:ss") {
  return moment(dateStr).format(pattern);
})
window.App = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

axios.defaults.withCredentials=true


