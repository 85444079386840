import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import Layout from '@/components/layout'
// import test from '@/views/test'

// 处理重复点击导航栏菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login/login')
  },

  {
    path: '/home',
    component: Layout,
    children: [{
      path: '/home',
      name: 'home',
      component: () => import('@/views/dashboard/index'),
      meta: { title: '首页', icon: 'el-icon-s-home' }
    }]
  },


  //律协端-------------------------------------------


  {
    path: '/userManageA',
    component: Layout,
    meta: { title: '律师律所管理', icon: 'el-icon-office-building' },

    hidden: (sessionStorage['isSuper'] != "true"),
    children: [{
      path: 'lawyerManageA',
      name: 'LawyerManageA',
      component: () => import('@/views/userManageA/LawyerManageA'),
      meta: { title: '律师信息管理', icon: 'el-icon-s-custom' }
    },
      {
        path: 'firmManageA',
        name: 'FirmManageA',
        component: () => import('@/views/userManageA/FirmManageA'),
        meta: { title: '律所信息管理', icon: 'el-icon-school' }
      },
      {
        path: 'lawyerTranscriptA',
        name: 'LawyerTranscriptA',
        component: () => import('@/views/userManageA/LawyerTranscriptA'),
        meta: { title: '律师考核汇总', icon: 'el-icon-s-data' }
      },
      {
        path: 'visitorManageA',
        name: 'VisitorManageA',
        component: () => import('@/views/userManageA/VisitorManageA'),
        meta: { title: '访客信息管理', icon: 'el-icon-user'}
      }]
  },
  {
    path: '/trainManageA',
    component: Layout,
    meta: { title: '培训管理', icon: 'el-icon-receiving' },

    hidden: (sessionStorage['isSuper'] != "true"),
    children: [{
      path: 'addTrainA',
      name: 'AddTrainA',
      component: () => import('@/views/trainManageA/AddTrainA'),
      meta: { title: '发布新培训', icon: 'el-icon-plus' }
    },
      {
      path: 'trainListA',
      name: 'TrainListA',
      component: () => import('@/views/trainManageA/TrainListA'),
      meta: { title: '培训列表', icon: 'el-icon-notebook-2' }
    },
      {
        path: 'trainAuditA',
        name: 'TrainAuditA',
        component: () => import('@/views/trainManageA/TrainAuditA'),
        meta: { title: '审核培训申报', icon: 'el-icon-document-checked' }
      },
      // {
      //   path: 'registerConfirm',
      //   name: 'RegisterConfirm',
      //   component: () => import('@/views/trainManageA/RegisterConfirm'),
      //   meta: { title: '审核参会结果', icon: 'el-icon-document-checked' }
      // }
      ]
  },
  {
    path: '/lawyerApplyManageA',
    component: Layout,
    meta: { title: '申报管理', icon: 'el-icon-tickets' },

    hidden: (sessionStorage['isSuper'] != "true"),
    children: [{
      path: 'lawyerApplyAuditA',
      name: 'LawyerApplyAuditA',
      component: () => import('@/views/lawyerApplyManageA/LawyerApplyAuditA'),
      meta: { title: '审核律师申报', icon: 'el-icon-document-checked' }
    },
      {
        path: 'batchApplyAuditA',
        name: 'BatchApplyAuditA',
        component: () => import('@/views/lawyerApplyManageA/BatchApplyAuditA'),
        meta: { title: '审核批量申报', icon: 'el-icon-document-copy' }
      }]
  },
  {
    path: '/systemA',
    component: Layout,
    meta: { title: '设置', icon: 'el-icon-s-tools' },
    hidden: (sessionStorage['isSuper'] != "true"),
    children: [{
      path: 'adminManageA',
      name: 'AdminManageA',
      component: () => import('@/views/systemA/AdminManageA'),
      meta: { title: '律协人员管理', icon: 'el-icon-s-custom' },
      hidden: ((sessionStorage['isSuper'] != "true") || (sessionStorage['isX'] != "supersuper") )
    },{
      path: 'yearA',
      name: 'YearA',
      component: () => import('@/views/systemA/YearA'),
      meta: { title: '年度管理', icon: 'el-icon-date' },
      hidden: ((sessionStorage['isSuper'] != "true") || (sessionStorage['isX'] != "supersuper") )
    },
      {
        path: 'systemA',
        name: 'SystemA',
        component: () => import('@/views/systemA/SystemA'),
        meta: { title: '个人中心', icon: 'el-icon-user-solid' }
      }

    ],
  },


  //律所端-------------------------------------------

  {
    path: '/userManageF',
    component: Layout,
    meta: { title: '律师管理', icon: 'el-icon-school' },


    hidden: (sessionStorage['isSuper'] != "false"),
    children: [{
      path: 'lawyerManageF',
      name: 'LawyerManageF',
      component: () => import('@/views/userManageF/LawyerManageF'),
      meta: { title: '律师信息管理', icon: 'el-icon-s-custom' }
    },
      {
        path: 'lawyerTranscriptF',
        name: 'LawyerTranscriptF',
        component: () => import('@/views/userManageF/LawyerTranscriptF'),
        meta: { title: '律师考核汇总', icon: 'el-icon-s-data' }
      },
      ],

  },
  {
    path: '/trainManageF',
    component: Layout,
    hidden: (sessionStorage['isSuper'] != "false"),
    meta: { title: '培训管理', icon: 'el-icon-receiving' },
    children: [
      {
        path: 'applyTrainF',
        name: 'ApplyTrainF',
        component: () => import('@/views/trainManageF/ApplyTrainF'),
        meta: { title: '申请培训', icon: 'el-icon-plus' }
      },
      {
        path: 'myApplyTrainF',
        name: 'MyApplyTrainF',
        component: () => import('@/views/trainManageF/MyApplyTrainF'),
        meta: { title: '我的申请', icon: 'el-icon-tickets' }
      },
      {
        path: 'trainListF',
        name: 'TrainListF',
        component: () => import('@/views/trainManageF/TrainListF'),
        meta: { title: '培训列表', icon: 'el-icon-notebook-2' }
      },
    ]
  },
  {
    path: '/lawyerApplyManageF',
    component: Layout,
    hidden: (sessionStorage['isSuper'] != "false"),
    meta: { title: '申报管理', icon: 'el-icon-tickets'},
    children: [
      {
        path: 'lawyerApplyAuditF',
        name: 'LawyerApplyAuditF',
        component: () => import('@/views/LawyerApplyManageF/LawyerApplyAuditF'),
        meta: { title: '律师申报审核', icon: 'el-icon-document-checked' }
      },
      {
        path: 'batchApplyF',
        name: 'BatchApplyF',
        component: () => import('@/views/LawyerApplyManageF/BatchApplyF'),
        meta: { title: '批量申报', icon: 'el-icon-document-add' }
      },
      {
        path: 'myBatchApplyF',
        name: 'MyBatchApplyF',
        component: () => import('@/views/LawyerApplyManageF/MyBatchApplyF'),
        meta: { title: '我的批量申报', icon: 'el-icon-document-copy' }
      },
    ]
  },
  {
    path: '/systemF',
    component: Layout,
    hidden: (sessionStorage['isSuper'] != "false"),
    children: [{
      path: 'systemF',
      name: 'SystemF',
      component: () => import('@/views/systemF/SystemF'),
      meta: { title: '个人中心', icon: 'el-icon-user-solid' }
    }]
  },
  {
    path: '/404',
    name: '404',
    hidden: true,
    component: () => import(/* webpackChunkName: "404" */ '@/views/error/404.vue')
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true },
]

const router = new VueRouter({
  // mode: 'history',
  // mode: 'hash',

  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()



    console.log(sessionStorage['isSuper'] == "true")
    console.log(sessionStorage['isSuper'] == "false")
    console.log(sessionStorage['isSuper'])




  // 未登录时，访问页面直接跳至登录页面
  if (sessionStorage['isLogin'] != 'true' && to.path != '/login') {
    next('/login')
  }
  // 律协登录时，访问律所端页面直接跳至404
  else if (sessionStorage['isSuper'] == "true" &&
    (to.path.indexOf("/userManageF") != -1 ||
      to.path.indexOf("/trainManageF") != -1 ||
      to.path.indexOf("/lawyerApplyManageF") != -1 ||
      to.path.indexOf("/systemF") != -1) ) {
    next('/404')
  }
  // 律所登录时，访问律协端页面直接跳至404
  else if (sessionStorage['isSuper'] == "false" &&
    (to.path.indexOf("/userManageA") != -1 ||
      to.path.indexOf("/trainManageA") != -1 ||
      to.path.indexOf("/lawyerApplyManageA") != -1   ||
    to.path.indexOf("/systemA") != -1   )) {
    next('/404')
  }
  else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
